import { Box, Card, CardActionArea, CardContent, CardHeader, Chip, Grid, Tooltip, Typography, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Paper, Accordion, AccordionDetails, AccordionSummary, Checkbox, IconButton, Dialog, Avatar, Select, Menu, MenuItem, Button, TextField, Divider, FormControlLabel, CardActions } from "@mui/material"
import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import ExpandMore from "@mui/icons-material/ExpandMore";
import GestureIcon from '@mui/icons-material/Gesture';
import DrawIcon from '@mui/icons-material/Draw';
import ChecklistStep from "../../ChecklistStep/ChecklistStep";
import { useEffect, useState } from "react";
import { createExtraChecklistStep, getChecklist, removeExtraChecklistStep } from "../../../util/api/checklist";
import HealthCheckChecklistStep from "./HealthCheckChecklistStep";
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch } from "react-redux";
import { setError } from "../../../actions/notificationActions";
import SessionSignDialog from "../SessionSignDialog";
// import ClientSignDialog from "./ClientSignDialog";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ColorLookup = {
  "H": "#ff4842",
  "W": "#ffc107",
  "R": "#83D100"
}

const ExtraStepCreatePopup = ({ checklistID, open, onClose, onSuccess }) => {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [inputs, setInputs] = useState([
    {
      title: "",
      input_type: 0,
      required: true
    }
  ])

  const dispatch = useDispatch();

  const clearInput = () => {
    setTitle("");
    setDescription("")
    setInputs([
      {
        title: "",
        input_type: 0,
        required: true
      }
    ])
  }
  const handleClose = () => {
    clearInput();
    onClose();
  }

  const addInput = () => {
    const newInput = {
      title: "",
      input_type: 0,
      required: true
    }

    setInputs(inputs => [...inputs, newInput]);
  }

  const removeInput = (index) => {
    setInputs(inputs => inputs.filter((s, i) => (i != index)))
  }

  const handleTypeChange = (index, value) => {
    inputs[index].input_type = value;

    setInputs(inputs => inputs.map((input, i) => {
      if (i == index) {
        input.input_type = value
      }
      return input;
    })
    );
  }

  const handleTitleChange = (index, value) => {
    setInputs(inputs => inputs.map((input, i) => {
      if (i == index) {
        input.title = value
      }
      return input;
    })
    );
  }

  const handleRequiredChange = (index, value) => {
    setInputs(inputs => inputs.map((input, i) => {
      if (i == index) {
        input.required = !input.required
      }
      return input;
    })
    );
  }

  const canSubmit = () => {
    return (title !== undefined && title !== ""
      && description !== undefined && description !== ""
      && inputs.length >= 1
    )
  }

  const handleSubmit = () => {
    createExtraChecklistStep(checklistID, title, description, inputs).then(() => {
      clearInput();
      onSuccess();
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <Card sx={{
        mt: 0,
        width: "100%",
        borderRadius: 0,
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid",
        borderColor: "#212529",
        borderRadius: 1,
        p: 1,
        overflow: "scroll"
      }}>

        <CardHeader
          title={"Create extra step"}
          subheader={
            <Box sx={{
              display: "flex",
              flexDirection: "column",

            }}>
              <Box display={"flex"} sx={{ color: "rgba(255, 255, 255, 0.6)" }}>

              </Box>

            </Box>
          }
        />
        <CardContent>
          <TextField
            sx={{ mb: 1 }}
            required={true}
            margin="dense"
            id={"title"}
            label={"Title"}
            fullWidth
            variant="standard"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            sx={{ mb: 1 }}
            required={true}
            margin="dense"
            id={"description"}
            label={"Description"}
            fullWidth
            variant="standard"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Divider sx={{ mt: 3, mb: 3, mr: 4 }} />

          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Typography sx={{ mt: 1, mb: 1 }} variant="h5">Inputs:</Typography>
            <Button onClick={() => addInput()} sx={{ mb: 2, mr: 0, ml: "auto" }} variant="outlined">Add Input</Button>
          </Box>

          {inputs.map((input, index) => {
            return <Box sx={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "left", alignItems: "center", gap: 3, mb: 3 }}>
              <Typography variant="body" textAlign={"center"}>{index + 1}.</Typography>
              <TextField
                id={"input_title" + index}
                label="Title"
                value={input.title}
                onChange={(e) => handleTitleChange(index, e.target.value)}
                sx={{ width: "100%" }}
              />
              <TextField
                id={"Input Type " + index}
                value={input.input_type}
                label="Input Type"
                sx={{ width: "100%" }}
                onChange={(e) => handleTypeChange(index, e.target.value)}
                select
              >
                <MenuItem value={"0"}>Checkbox</MenuItem>
                <MenuItem value={"1"}>Textfield</MenuItem>
                <MenuItem value={"2"}>Photo Upload</MenuItem>
                <MenuItem value={"3"}>Date</MenuItem>
              </TextField>

              <FormControlLabel control={<Checkbox checked={input.required} onChange={(e) => handleRequiredChange(index, e.target.value)} color="primary" />} label="Required" />

              <Tooltip title="Remove Input"><IconButton sx={{ color: "#83D100" }} disabled={inputs.length <= 1} onClick={() => removeInput(index)}><RemoveCircleIcon /></IconButton></Tooltip>


            </Box>
          })
          }

        </CardContent>
        <Divider />
        <CardActions sx={{ display: "flex", justifyContent: "right", gap: 3, m: 2 }}>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button disabled={!canSubmit()} onClick={handleSubmit} variant="outlined">Add Step</Button>
        </CardActions>
      </Card>
    </Dialog>
  )
}

const HealthCheckChecklistTile = ({ checklistID, onClick, defaultClosed, onCompleted }) => {

  const [selectedStep, setSelectedStep] = useState(undefined)
  const [checklistComplete, setChecklistComplete] = useState(false);
  const [checklist, setChecklist] = useState(undefined);
  const [signStep, setSignStep] = useState(undefined);
  const [extraStepPopupOpen, setExtraStepPopupOpen] = useState(false);

  const dispatch = useDispatch();

  const completedSteps = checklist?.steps?.filter((step) => {
    return step.completed
  }).length

  const approvedSteps = checklist?.steps?.filter((step) => {
    return step.approved
  }).length

  const progressColor = checklist?.completed ? "success" : "warning"
  const approvedProgressColor = checklist?.approved ? "success" : "warning"

  const openStep = (step) => {
    setSelectedStep(step)
  }

  const onCloseDialog = () => {
    setSelectedStep(undefined);
    fetchChecklist();
  }

  const fetchChecklist = () => {
    getChecklist(checklistID).then((res) => {
      setChecklist(res.data)
      setChecklistComplete(res.data.completed)
    })
  }

  const checkChecklistComplete = () => {
    onCompleted();
  }

  const handleNewChecklistStepCreate = () => {
    setExtraStepPopupOpen(false);
    fetchChecklist();
  }

  const handleExtraStepDelete = (stepID) => {
    removeExtraChecklistStep(checklistID, stepID).then(() => {
      fetchChecklist();
    }).catch((e) => {
      dispatch(setError(e.response?.data?.detail))
    })
  }

  useEffect(() => {
    fetchChecklist()
  }, [checklistID])


  return (

    <Card sx={{
      mt: 0,
      width: "100%",
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid",
      borderColor: "#212529",
      borderRadius: 1,
      p: 0,
      mb: 0,
      mt: 1,
      pt: 0,
      // border: "1px solid white"
    }}>
      <Dialog fullWidth maxWidth="md" open={selectedStep !== undefined} onClose={onCloseDialog}>
        <HealthCheckChecklistStep onSignLocally={() => { setSignStep(selectedStep); setSelectedStep(undefined) }} disabled={false} onUpdate={checkChecklistComplete} stepID={selectedStep?.id} checklistID={checklist?.id} />
      </Dialog>

      <ExtraStepCreatePopup checklistID={checklistID} open={extraStepPopupOpen} onClose={() => setExtraStepPopupOpen(false)} onSuccess={handleNewChecklistStepCreate} />

      <Accordion defaultExpanded={!defaultClosed}>
        <AccordionSummary expandIcon={<ExpandMore sx={{ color: "white" }} />}>

          <CardHeader
            sx={{ pl: 2, pt: 2, }}
            title={checklist?.title}
            action={
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1
              }}>
                <Tooltip title="Checklist Status">
                  <Chip
                    color={progressColor}
                    sx={{ ml: 4 }}
                    label={
                      <Box sx={{
                        padding: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                      }}>
                        {`${completedSteps} of ${checklist?.steps?.length} steps completed`}
                        {checklist?.completed && <CheckIcon sx={{ ml: 1 }} />}
                      </Box>
                    }
                  />
                </Tooltip>

                {/* <Tooltip title="Checklist Status">
                  <Chip
                    color={approvedProgressColor}
                    sx={{ ml: 4 }}
                    label={
                      <Box sx={{
                        padding: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                      }}>
                        {`${approvedSteps} of ${checklist?.steps?.length} steps approved`}
                        {checklist?.approved && <CheckIcon sx={{ ml: 1 }} />}
                      </Box>
                    }
                  />
                </Tooltip> */}
              </Box>
            }
          />
        </AccordionSummary>
        <CardContent sx={{ p: 0, p: "0 !important" }}>

          <AccordionDetails>
            <Grid container sx={{
              width: "100%",
              // background: "#83D100"
            }}>
              {/* <Grid sx={{ pl: 1, pt: 1, pb: 1 }} item xs={10}>
            {checklist?.description}
          </Grid> */}
              <TableContainer component={Paper} sx={{ borderRadius: 0, p: 0, border: " 1px solid white", borderRadius: 1 }}>
                <Table sx={{ p: 0, minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ p: 0, pt: "0 !imporant", mt: 1, borderRadius: 0 }}>
                    <TableRow>
                      <TableCell sx={{ width: "75%" }}>Inspecties</TableCell>
                      <TableCell align="center">Check</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>

                  </TableHead>
                  <TableBody sx={{ pb: 0 }}>
                    {checklist?.steps?.map((step) => (

                      <StyledTableRow
                        key={step.title}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, p: 0 }}
                      >
                        <TableCell component="th" scope="row">
                          {step.title}
                        </TableCell>
                        <TableCell sx={{ p: 0 }} align="center">
                          {step?.completed ||
                            <Tooltip title="Complete Step">
                              <div>
                                <IconButton onClick={() => { openStep(step) }}><DrawIcon /></IconButton>
                              </div>
                            </Tooltip>
                          }
                          {step?.completed && <Tooltip title="Completed">
                            <div>
                              <IconButton onClick={() => { openStep(step) }}><CheckIcon sx={{ color: "#83D100" }} /></IconButton>
                            </div>
                          </Tooltip>}
                        </TableCell>
                        {/* <TableCell align="right">{step.fat}</TableCell> */}
                        <TableCell align="center" >
                          <Box
                            sx={{
                              display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 1
                            }}
                          >
                            {(0 === step?.health_status || null === step?.health_status) && <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: "#000" }}></Box>None</Box>}

                            {1 === step?.health_status && <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: "#DD1D21" }}></Box>Safety Critical</Box>}

                            {2 === step?.health_status && <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: "#FFC000" }}></Box>Advisory</Box>}

                            {3 === step?.health_status && <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                              <Box sx={{ borderRadius: "50%", width: "20px", height: "20px", background: "#94D500" }}></Box>Satisfactory</Box>}

                          </Box>
                        </TableCell>
                        <TableCell>
                          {step?.is_extra_step && !checklist?.disabled && <Tooltip title="Remove Extra Step"><IconButton onClick={() => handleExtraStepDelete(step.id)} sx={{ color: "#83D100" }} ><RemoveCircleIcon /></IconButton></Tooltip>}
                        </TableCell>
                      </StyledTableRow>


                    ))}
                    <TableRow>
                      <TableCell colSpan={4} sx={{ border: "none", p: 0 }}>
                        {!checklist?.disabled && <Button startIcon={<AddIcon />} onClick={() => setExtraStepPopupOpen(true)} sx={{ width: "100%", height: 50 }}>Add extra step</Button>}
                      </ TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </AccordionDetails>

        </CardContent>
      </Accordion>

      {/* </CardActionArea> */}
    </Card >
  )
}

export default HealthCheckChecklistTile;