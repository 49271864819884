import { Grid, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react'


import ProjectTile from "../../components/ProjectOverview/ProjectTile"
import ProjectCreateDialog from '../../components/ProjectOverview/ProjectCreateDialog';
import TopBar from "../../components/ProjectOverview/TopBar";

import { getProjectOverview, createInspectionProject, createHealthCheckProject, deleteProject } from '../../util/api';

import ProjectApprovalPopup from '../../components/Approval/ProjectApprovalPopup';
import Loading from '../../components/Loading';
import Scrollbar from '../../components/Scrollbar';

const ProjectOverview = () => {
  const [loading, setLoading] = useState(true);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchValue, setSearchValue] = useState("")
  const [projectPopupOpen, setProjectPopupOpen] = useState(false)
  const [openProjectID, setOpenProjectID] = useState(undefined)


  useEffect(() => {
    handleSearch(searchValue)
  }, [searchValue])

  const handleCloseProjectCreate = (data) => {

    if (data) {
      const postData = {
        ...data,
      }

      if (data?.inspectionProject) {
        delete postData['inspectionProject'];
        createInspectionProject(postData)
          .then(() => {
            fetchOverview()
          })
      } else if (data?.healthCheckProject) {
        delete postData['healthCheckProject'];

        createHealthCheckProject(postData)
          .then(() => {
            fetchOverview()
          })
      }

    }

    setCreateDialogOpen(false)
  }

  const fetchOverview = () => {
    getProjectOverview()
      .then((data) => {
        setProjects(data.data);
        setFilteredProjects(data.data)
        setLoading(false);
      })
  }

  const handleProjectDelete = (projectID) => {
    deleteProject(projectID)
      .then(() => { fetchOverview() })
  }

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  }

  const handleSearch = (rawVal) => {
    const value = rawVal?.toLowerCase()
    const newProjects = projects.filter((proj) => {
      return (value === ""
        || proj?.company_name?.toLowerCase().includes(value)
        || proj?.location?.toLowerCase().includes(value)
        || proj?.contact_name?.toLowerCase().includes(value)
        || proj?.service_contract?.toLowerCase().includes(value)
        || proj?.ventil_reference?.toLowerCase().includes(value)
      )
    })

    setFilteredProjects(newProjects);
  }

  const handleClientClick = (client) => {
    setSearchValue(client)
  }

  const handleClosePopup = () => {
    setOpenProjectID(undefined)
    setProjectPopupOpen(false)
    setLoading(true)
  }

  const handleProjectSubmit = (projectID) => {
    setOpenProjectID(projectID)
    setProjectPopupOpen(true)
  }

  useEffect(() => {
    if (loading) {
      fetchOverview()
    }
  }, [loading])

  return (
    loading ? <Loading /> :

      <Box sx={{ p: 1, pt: 0 }}>

        <ProjectCreateDialog open={createDialogOpen} onClose={handleCloseProjectCreate} />
        <ProjectApprovalPopup open={projectPopupOpen} projectID={openProjectID} onClose={handleClosePopup} />

        <TopBar
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          cancelSearch={() => setSearchValue('')}
          onCreate={() => { setCreateDialogOpen(true) }}
        />
        <Scrollbar sx={{ maxHeight: "calc(100vh - 260px)" }}>
          < Grid container justifyContent="center" sx={{
            ml: 0,
            mt: 0,
            width: "100%",
            border: "1px solid",
            background: 'None',
            borderColor: '#212529',
            borderRadius: 1,
          }}>

            {filteredProjects.map((project) => {
              return (
                <ProjectTile
                  project={project}
                  onDelete={handleProjectDelete}
                  onClientClick={() => handleClientClick(project?.company_name)}
                  onProjectSubmitClick={() => handleProjectSubmit(project?.id)}
                />
              )
            })}

            {filteredProjects.length === 0 && <Box sx={{ pt: 10, pb: 10 }}>
              <Typography variant="h4">
                No projects found
              </Typography>
            </Box>}
          </Grid>
        </Scrollbar>
      </Box >
  )
}

export default ProjectOverview;
