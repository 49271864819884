import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SignatureCanvas from 'react-signature-canvas'

import './signature.scss'
import { signChecklistStep } from "../../util/api/checklist";
import { directSignSession } from "../../util/api/session";

const ACTION_TABLE = {
  "A": "Approve",
  "M": "Monitor",
  "R": "Review",
  "H": "Hold",
  "W": "Witness",
  None: ""
}

const SessionSignDialog = ({ session, onClose, open, onSignoff }) => {
  const [clientName, setClientName] = useState(session?.ventil_tech)
  const [loading, setLoading] = useState(false);

  const sigCanvas = useRef({});

  const handleClose = () => {
    onClose();
  }

  useEffect(() => {
    setClientName(session?.ventil_tech)
  }, [session])

  const handleSign = () => {
    setLoading(true);
    const trimmedSig = trimmed()

    trimmedSig.toBlob((blob) => {
      // console.log(blob)
      const temp = new File([blob], "signature.png")
      directSignSession(session?.id, clientName, temp).then((e) => {
        onSignoff();
      }).catch((e) => {
        setLoading(false);
      })
    }, "image/png");

    // console.log(trimmedSig)
  }

  const clear = () => sigCanvas.current.clear();

  const trimmed = () => sigCanvas.current.getTrimmedCanvas()

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogContent>
        <DialogTitle sx={{ p: 0 }}>Project -  Sign Off</DialogTitle>

        <Box sx={{ background: "white", borderRadius: "10px", p: 0, border: "2px solid black", mt: 2 }}>
          {!loading && !session?.final_signature_name && <SignatureCanvas ref={sigCanvas}
            penColor="#000"
            // backgroundColor="#fff"
            canvasProps={{
              className: "signatureCanvas"
            }} />}
          {!loading && session?.final_signature_path && <img src={session?.final_signature_path} className="signatureCanvas" />}

          {loading && <Box sx={{ minHeight: "50vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={"100px"} sx={{ width: "100%" }} />
          </Box >}
        </Box>
      </DialogContent>
      <DialogActions sx={{ alignItems: "center", display: "flex" }}>
        {!session?.final_signature_name && <TextField
          id="standard-basic"
          label="Name"
          variant="standard"
          value={clientName}
          onChange={(e) => { setClientName(e.target.value) }}

          sx={{ float: "right", ml: 2, mr: "auto" }}
        />}



        {session?.final_signature_name && <Typography sx={{ float: "right", ml: 2, mr: "auto" }}>Signed by: {session?.final_signature_name}</Typography>}
        {!session?.final_signature_path && <Button disabled={loading} onClick={clear}>Clear</Button>}
        <Button onClick={handleClose} disabled={loading}>Close</Button>
        {!session?.final_signature_path && <Button disabled={clientName === "" || loading} onClick={handleSign}>Sign</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default SessionSignDialog;