import axios from "axios";

const BASE_URL = '/api/session/'

export const updateSessionInfo = (sessionID: string, payload: any) => {
  return axios.put(BASE_URL + `${sessionID}/`, payload)
}

export const getSessionIssues = (sessionID: string) => {
  return axios.get(BASE_URL + `${sessionID}/issues`)
}

export const addChecklistsToProject = (projectID: string, checklists: any) => {
  return axios.post(BASE_URL + `${projectID}/checklist/`, checklists)
}

export const removeChecklistsFromProject = (projectID: string, checklists: any) => {
  return axios.post(BASE_URL + `${projectID}/checklist/remove`, checklists)
}

export const orderChecklistsOnProject = (projectID: string, checklists: any) => {
  return axios.post(BASE_URL + `${projectID}/checklist/order`, checklists)
}

export const startHealthCheckSession = (projectID: string) => {
  return axios.post(BASE_URL + `${projectID}/startHealthCheck`)
}

export const directSignSession = (sessionID: string, signName: string, signatureFile: any) => {
  let formData = new FormData();
  formData.append("signature_file", signatureFile)
  formData.append('signame', signName)

  return axios.post(BASE_URL + `${sessionID}/directSign`, formData)
}